<template>
  <div class="problem-answer-container">
    <div class="problem-answer-title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>理论题</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/trainAdmin/problemAnalysis/index' }">问答评分</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/trainAdmin/problemAnalysis/problemStudentDetail',query:{pid:answerData.pid} }">学生评分</el-breadcrumb-item>
        <el-breadcrumb-item style="font-weight: bold"
          >具体评分</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="problem-answer-nav" style="margin-top: 34px">
      <span class="problem-answer-stuname">学生: {{ answerData.student_name }}</span>
      <span class="problem-answer-store">评分情况：</span>
      <el-select
        v-model="selectValue"
        placeholder="请选择"
        clearable
        @change="changeListData"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-table
            :data="answerList"
            stripe
            height="100%"
            style="width: 100%"
            border
            :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
            :header-cell-style="{
          color: 'rgba(19, 19, 27, 1)',
          background: '#F3F4FA',
          borderRight: 'unset',
        }"
    >
      <el-table-column prop="id" label="序号" width="329" align="center">
      </el-table-column>
      <el-table-column
              prop="answer_is_true"
              label="评分情况"
              width="320"
              align="center"
      >
        <template slot-scope="scope">
            <span>{{
              scope.row.answer_is_true == 2 ? "未评分" : "已评分"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="问答题" width="330" align="center">
        <template slot-scope="scope">
          <span v-html="scope.row.title"></span>
        </template>
      </el-table-column>
      <el-table-column
              prop="answer_stu_answer"
              label="学生答案"
              width="330"
              align="center"
      >
      </el-table-column>
      <el-table-column prop="options" label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-radio
                  v-model="scope.row.answer_is_true"
                  :label="1"
                  style="margin-bottom: 15px"
                  :disabled="scope.row.flag"
          >正确</el-radio
          >
          <el-radio
                  v-model="scope.row.answer_is_true"
                  :label="0"
                  style="margin-bottom: 15px"
                  :disabled="scope.row.flag"
          >错误</el-radio
          >
          <el-button type="primary" @click="submit(scope.row)"
          >确定</el-button
          >
          <el-button @click="updateAnswerData(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            class="pages-center"
            :current-page="listPages.currentPageNum"
            :page-size="listPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="listPages.total"
            @current-change="pageCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { showAnswer, updateAnswerListData } from "@/utils/apis";
export default {
  data() {
    return {
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 2,
          label: "未评分",
        },
        {
          value: 1,
          label: "已评分",
        },
      ],
      radio: "",
      answerList: [],
      selectValue: "",
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      answerData:{},
    };
  },
  mounted() {
    let obj = {
      id:this.$route.query.id,
      user_id:this.$route.query.user_id,
      student_name: this.$route.query.student_name,
      pid: this.$route.query.pid,
    }
    this.answerData = obj
    this.showAnswerListData();
  },
  methods: {
    showAnswerListData() {
      let params = {
        times_id: this.answerData.id,
        user_id: this.answerData.user_id,
        limit: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showAnswer(params).then((res) => {
        if (res.code === 200) {
          this.answerList = res.data.list;
          this.answerList.forEach((item) => {
            item.flag = false;
            item.check = false;
            return item;
          });
          console.log(this.answerList);
          this.listPages.total = res.data.total;
        }
      });
    },

    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showAnswerListData();
    },

    updateAnswerData(row) {
      if (row.flag == false) {
        this.$message({
          message: "修改评分前，请先保存评分",
          type: "warning",
        });
      } else {
        this.$confirm("是否确定修改评分结果？, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.updateFlag(row);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消评分",
            });
          });
      }
    },

    updateFlag(row) {
      row.flag = !row.flag;
      this.$forceUpdate();
    },

    submit(row) {
      if (row.answer_is_true == 2) {
        this.$message({
          message: "请先选择一项后点击确定按钮保存评分",
          type: "error",
        });
      } else {
        this.updateFlag(row);
        let params = {
          id: row.answer_id,
          is_true: row.answer_is_true,
        };
        updateAnswerListData(params).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "评分成功",
              type: "success",
            });
            // this.showAnswerListData();
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },
    // if (row.flag == false) {
    //   this.$message({
    //     message: "请先选择一项后点击确定按钮保存评分",
    //     type: "warning",
    //   });
    // } else {
    //   this.updateFlag(row);
    // }
    // console.log(row)
    // this.updateFlag(row);
    // let params = {
    //   id: row.answer_id,
    //   is_true: row.answer_is_true,
    // };
    //

    changeListData() {
      let params = {
        times_id: this.answerData.id,
        user_id: this.answerData.user_id,
        status: this.selectValue,
        limit: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showAnswer(params).then((res) => {
        if (res.code === 200) {
          this.answerList = res.data.list;
          this.listPages.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.problem-answer-container {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  .problem-answer-nav {
    .problem-answer-stuname {
      font-weight: bold;
      font-size: 16px;
    }
    .problem-answer-store {
      margin-left: 50px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .el-table {
    margin-top: 34px;
    flex:1;
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #fff;
      background: rgba(17, 34, 216, 1);
    }
    ::v-deep .el-radio__input.is-checked + .el-radio__label {
      color: rgba(17, 34, 216, 1);
    }

    ::v-deep .el-button--primary {
      border-color: rgba(17, 34, 216, 1);
      background: rgba(17, 34, 216, 1);
    }
  }
  ::v-deep .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link {
      font-weight: 400;
      color: #606266;
    }
  }
  .pages-center {
    margin-top: 15px;
    text-align: center;
  }
}
</style>